import React from "react";
import { Layout } from "../components/Layout";
import SEO from "../components/Seo";

export default () => {
  return (
    <Layout>
      <SEO title="Imprint" description="Feedback Fish Imprint" />

      <div>imprint...</div>
    </Layout>
  );
};
